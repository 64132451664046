<template>
  <div class="clearfix">
    <a-upload
      :action="uploadImgUrl"
      :list-type="type == 'image' ? 'picture-card' : 'picture'"
      :show-upload-list="false"
      :accept="accept"
      :headers="headers"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <img v-if="value && type == 'image'" :src="value" alt="avatar" />
      <div v-if="type == 'file'">
        <a-button> <a-icon type="upload" /> 上传 </a-button>
      </div>
      <div v-if="value == '' || value == null">
        <span v-if="type == 'image'">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">
            上传
          </div>
        </span>
      </div>
    </a-upload>
    <span v-if="value && type == 'file'">
      <br>
      <a :href="value" class="link" target="_blank" >{{ value }}</a>
    </span>
  </div>
</template>

<script>
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  name: 'ImageUpload',
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'image'
    },
    count: {
      type: Number,
      default: 1
    },
    accept: {
      type: String,
      default: ''
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      open: false,
      uploadImgUrl: process.env.VUE_APP_BASE_API + '/common/local/file/upload',
      headers: {
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
      },
      typeFileMap: {
        '.zip': 'application/x-zip-compressed',
        '.rar': 'application/x-compressed'
      }
    }
  },
  mounted () {
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    async handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        if (info.file.response.code !== '20001') {
          this.$message.error('上传失败' + info.file.response.msg)
          this.loading = false
          return
        }
        this.loading = false
        // info.file.response.url 历史写法
        // info.file.response.data 修改上传文件类型做出调整 - 后端直接在 data 字段上中直接返回
        this.$emit('input', info.file.response.data)
      }
    },
    beforeUpload (file) {
      const accept = this.accept
      const acceptStr = accept.split(',')
      const isType = acceptStr.some((item) => file.type === this.typeFileMap[item])
      // 文件类型(file.type)、大小限制(file.size)
      if (this.type === 'file') {
        if (!isType) {
          this.$message.error(`上传文件类型错误，需上传已${accept}为后缀的文件!`)
          return isType
        }
        return isType
      }
      // 压缩包不限制大小，只有不是压缩包的前提才做限制
      if (this.type === 'image') {
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
          this.$message.error('图片大小限制 2MB!')
        }
        return isLt2M
      }
    }
  }
}
</script>
<style lang="less" scoped>
img {
  width: 128px;
  height: 128px;
}
.link {
  display: inline-block;
  line-height: 26px;
}

</style>
